import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import App from "./App";
import reducer, { initialState } from "./context/reducer";
import { StateProvider } from "./context/StateProvider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const envType = process.env.REACT_APP_ENV;

//tracing UI errors using Sentry
if (envType === "PROD" || envType === "BETA") {
    Sentry.init({
        dsn: "https://6fcb5e8f984047938a079ae45b8c49a4@o1173407.ingest.sentry.io/6505329",
        integrations: [new BrowserTracing()],
        release: "1.0",
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}


ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
    <StateProvider
      initialState={initialState}
      reducer={reducer}
    >
      <App />
    </StateProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
