export const initialState = {
  hotelId: localStorage.getItem("hotelId") || "",
  bookingId: localStorage.getItem("bookingId") || "",
  currencySymbol: "",
  flowActive: false,
  // itineraryData: JSON.parse(localStorage.getItem("itineraryData")) || {},
  bookingPageData: JSON.parse(localStorage.getItem("bookingPageData")) || {},
  hotelPageData: JSON.parse(localStorage.getItem("hotelPageData")) || {},
  roomPageData: JSON.parse(localStorage.getItem("roomPageData")) || {},
  bookingSelfServiceData:
    JSON.parse(localStorage.getItem("bookingSelfServiceData")) || {},
  selfServiceFlags: {},
  earlyCheckInSlots: {},
  lateCheckOutSlots: {},
  flowData: {},
  slotselectedinUpsellforEarlyCheckin: "",
  actionTaken: {
    selfCheckin: false,
    earlyCheckin: false,
    lateCheckOut: false,
  },
  razorpayPayment: false,
  payloadId: "",
  finalPayload: JSON.parse(localStorage.getItem("finalPayload")) || {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_RAZORPAY_FLAG":
      localStorage.setItem("razorpayFlag", action.razorpayPayment);
      localStorage.setItem("payloadId", action.payloadId);
      return {
        ...state,
        razorpayPayment: action.razorpayPayment,
        payloadId: action.payloadId,
      };
    case "SET_HOTEL_ID":
      localStorage.setItem("hotelId", action.hotelId);
      return {
        ...state,
        hotelId: action.hotelId,
      };
    case "SET_CURRENCY_SYMBOL":
      localStorage.setItem("currencySymbol", action.currencySymbol);
      return {
        ...state,
        currencySymbol: action.currencySymbol,
      };
    case "SET_BOOKING_ID":
      localStorage.setItem("bookingId", action.bookingId);
      return {
        ...state,
        bookingId: action.bookingId,
      };
    case "SET_FLOW_ACTIVE":
      return {
        ...state,
        flowActive: action.flowActive,
      };
    case "SET_ACTION_TAKEN":
      return {
        ...state,
        actionTaken: action.actionTaken,
      };
    // case "SET_ITINERARY_DATA":
    //   localStorage.setItem(
    //     "itineraryData",
    //     JSON.stringify(action.itineraryData)
    //   );
    //   return {
    //     ...state,
    //     itineraryData: action.itineraryData,
    //   };
    case "SET_REQUIRED_DATA":
      localStorage.setItem(
        "bookingPageData",
        JSON.stringify(action.bookingPageData)
      );
      localStorage.setItem(
        "hotelPageData",
        JSON.stringify(action.hotelPageData)
      );
      localStorage.setItem("roomPageData", JSON.stringify(action.roomPageData));
      localStorage.setItem(
        "bookingSelfServiceData",
        JSON.stringify(action.bookingSelfServiceData)
      );
      return {
        ...state,
        bookingPageData: action.bookingPageData,
        hotelPageData: action.hotelPageData,
        roomPageData: action.roomPageData,
        bookingSelfServiceData: action.bookingSelfServiceData,
      };
    case "SET_SS_CART_DATA":
      localStorage.setItem("cartData", JSON.stringify(action?.cartData));
      return {
        ...state,
        cartData: action?.cartData,
      };

    case "SET_SELF_SERVICE_FLAGS":
      localStorage.setItem(
        "selfServiceFlags",
        JSON.stringify(action.selfServiceFlags)
      );
      return {
        ...state,
        selfServiceFlags: action.selfServiceFlags,
      };

    case "SET_EARLY_CHECKIN_SLOTS":
      return {
        ...state,
        earlyCheckInSlots: action.earlyCheckInSlots,
      };

    case "SET_LATE_CHECKOUT_SLOTS":
      return {
        ...state,
        lateCheckOutSlots: action.lateCheckOutSlots,
      };
    case "SET_FLOW_DATA":
      return {
        ...state,
        flowData: action.data,
      };
    case "SET_FLOW_EARLY_CHECKIN_SLOT":
      return {
        ...state,
        slotselectedinUpsellforEarlyCheckin: action.time,
      };

    case "SET_FINAL_PAYLOAD_DATA":
      localStorage.setItem("finalPayload", JSON.stringify(action.finalPayload));

      return {
        ...state,
        finalPayload: action.finalPayload,
      };

    default:
      return state;
  }
};

export default reducer;
