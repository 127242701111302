import { Button, IconButton } from "@chakra-ui/button";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Heading, Stack, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { GoKey } from "react-icons/go";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Tag,
} from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";
import { baseUrl } from "../utils/constants";
import { useStateValue } from "../context/StateProvider";

function CommonLockCode(props) {
  const [
    { bookingId, selfServiceFlags, roomPageData, bookingPageData },
    dispatch,
  ] = useStateValue();

  const [commonAreaLockCodes, setCommonAreaLockCodes] = useState(null);

  useEffect(() => {
    if (
      bookingPageData?.commonLockMap !== undefined &&
      Object.keys(bookingPageData?.commonLockMap).length
    ) {
      setCommonAreaLockCodes(bookingPageData?.commonLockMap);
    }
  }, [bookingPageData]);

  return (
    <Stack p="3" spacing="10">
      <Stack align="center" direction="row">
        <IconButton
          aria-label="back to homepage"
          borderRadius="full"
          icon={<ArrowBackIcon />}
          onClick={() => window.history.back()}
        />
        <Heading fontSize="md" color="blackAlpha.900" fontWeight="medium">
          Common Lock Codes
        </Heading>
      </Stack>
      <Stack>
        {/* <div
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            margin: "1rem",
            marginTop: "0rem",
            padding: "1em",
          }}
        >
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex mb-3">
              <h1>Checkin</h1>
              <p style={{ fontWeight: "bold", marginBottom: "1rem" }}>
                {moment(
                  bookingPageData?.bookingCin,
                  "DD-MM-YYYY hh:mm:ss"
                ).format("MMMM Do YYYY, h a")}
              </p>
            </div>
            <div className="d-flex mt-3">
              <h1>Checkout</h1>
              <p style={{ fontWeight: "bold" }}>
                {moment(
                  bookingPageData?.bookingCout,
                  "DD-MM-YYYY hh:mm:ss"
                ).format("MMMM Do YYYY, h a")}
              </p>
            </div>
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            width: "120px",
            height: "120px",
            backgroundColor: "green",
            borderRadius: "50%",
            margin: "auto",
            marginTop: "0rem",
            background: "linear-gradient(150deg, #1ab394e6, #1a91aee6)",
          }}
        >
          <GoKey style={{ margin: "auto", color: "white" }} size={75} />
        </div>
        <h1
          style={{
            margin: "auto",
            marginTop: "1.5rem",
            fontSize: "2rem",
            fontWeight: "500",
          }}
        >
          Common Area Codes
        </h1>
        {commonAreaLockCodes !== null &&
          Object.keys(commonAreaLockCodes)?.map((key) => (
            <Tag size={"lg"} key={"lg"} borderRadius="md">
              <p style={{ fontSize: "23px", textAlign: "start", width: "60%" }}>
                {key}
              </p>
              <p
                style={{ fontSize: "23px", textAlign: "center", width: "10%" }}
              >
                {" : "}
              </p>
              <p style={{ fontSize: "23px", textAlign: "end", width: "50%" }}>
                {commonAreaLockCodes[key]}{" "}
              </p>
            </Tag>
          ))}
      </Stack>
      {/* <Button
        width="auto"
        m="0.75rem !important"
        left="0"
        right="0"
        bottom="0"
        position="fixed"
        onClick={handleForward}
        bg="linear-gradient(150deg, #1ab394e6, #1a91aee6)"
        _hover={{ bg: "linear-gradient(150deg, #1ab394, #1a91ae)" }}
        boxShadow="lg"
        style={{
          zIndex: "1031",
          color: "white",
          fontWeight: "600",
        }}
      >
        Give feedback
      </Button> */}
    </Stack>
  );
}

export default CommonLockCode;
